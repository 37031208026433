import axios from "axios";
import { API } from "../../../config/API";

export const GetNjayarCat: any = async () => {
  return new Promise(async function (resolve, reject) {
    try {
      const query = `
      query{
        njayarprabhaadhams( 
              sort: "id:desc",
              pagination: { start: 0, limit: 100 }) {
              data {
                id
                attributes {
                  category
                }
              }
              }
      }`;
      axios({
        method: "post",
        url: API.GRAPHQL,
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          query: query,
        },
      })
        .then((response: any) => {
          let obj = {
            status: true,
            data: response?.data?.data.njayarprabhaadhams,
          };
          resolve(obj);
        })
        .catch((error: any) => {
          let obj = { status: false };
          reject(obj);
        });
    } catch (err) {
      let obj = { status: false };
      reject(obj);
      console.log(err);
    }
  });
};
