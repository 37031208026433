import {
  Button,
  Form,
  Input,
  message,
  notification,
  Radio,
  Select,
} from "antd";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import Loadingbox from "../../../component/loadingbox";
import { API } from "../../../config/API";
import { BsFillPlusCircleFill, BsUpload } from "react-icons/bs";
import PageHeader from "../../../component/pageHeaders/index";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { LuMinus } from "react-icons/lu";
import ImagePicker from "../../../component/image-picker";
import moment from "moment";
import { useSelector } from "react-redux";
import { LoadTags } from "../../../utils/commonapis";
import { addTags } from "../../newsScreen/utils/apiRequest";
import { GetNjayarCat } from "../queries/getNjayarCategory";

function NewFormNjayarArticles() {
  const Tags = useSelector((state: any) => state.Tags?.data);
  const navigate = useNavigate();
  let { id }: any = useParams();
  const location = useLocation();
  const props = location?.state;
  const restTags = props?.item?.attributes?.NjayarTags?.split("|");
  const restCat = props?.item?.attributes?.category?.split("|");

  let InitialBody =
    props?.item &&
    props?.item?.id &&
    props?.item?.attributes &&
    props?.item?.attributes?.Body;
  const [form] = Form.useForm();
  const [content, setContent] = useState<any>(InitialBody);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(true);
  const [loading3, setLoading3] = useState(false);
  const [returnedNewsId, setReturnedNewsId] = useState() as any;
  const [imageuploaddata, setImageuploaddata] = useState<any>({
    imageurl1: props?.item?.attributes?.image1 || "",
    imageurl2: props?.item?.attributes?.image2 || "",
    imageurl3: props?.item?.attributes?.profile || "",
  });
  const [uploadImg, setUploadImg] = useState(false);
  const [imageType, setImageType] = useState("");
  const [imageName, setImageName] = useState("");
  const [isChecked, setIsChecked] = useState<any>(
    props?.item?.attributes?.isHighPriority === true
      ? 1
      : props?.item?.attributes?.isSecondPriority === true
      ? 2
      : props?.item?.attributes?.isThirdPriority === true
      ? 3
      : null
  );

  const [authorImg, setAuthorImg] = useState();
  const [tags, setTags] = useState<any>([]);
  const [njayarCAt, setNjayarCAt] = useState<any>();

  const { imageurl1, imageurl2, imageurl3 } = imageuploaddata;
  let pic1 = imageurl1;
  let pic2 = imageurl2;
  let pic3 = imageurl3;

  useEffect(() => {
    loadNjayarCAt();
  }, []);

  const loadNjayarCAt = async () => {
    let result = await GetNjayarCat();
    if (result.status) {
      const res = result.data?.data.filter(
        (item: any) => item?.attributes?.category?.length
      );
      const uniqueCategories = res?.reduce((acc: string[], current: any) => {
        const category = current?.attributes?.category;
        if (!acc.includes(category)) {
          acc.push(category);
        }
        return acc;
      }, []);
      setNjayarCAt(uniqueCategories);
    } else {
      message.error("Something went wrong...!");
    }
  };

  const handleEditorChange = (content: any, editor: any) => {
    setContent(content);
  };
  useEffect(() => {
    loadTags();
  }, []);

  const loadTags = async () => {
    try {
      if (!Tags.length) {
        const res: any = await LoadTags();
        setTags(res);
      } else {
        setTags(Tags);
      }
    } catch (error) {
    } finally {
      setLoading3(false);
    }
  };

  function deleteImagesFromList(key: any) {
    try {
      setImageuploaddata((prevImages: any) => {
        const updatedImages = { ...prevImages };
        updatedImages[key] = "";
        return updatedImages;
      });
      notification.success({
        message: `Image Removed`,
      });
    } catch (err) {
      notification.error({
        message: `Failed to remove`,
      });
    }
  }

  const addImagesToList = (data: any) => {
    try {
      let DataObj = imageuploaddata;
      if (data.type === "image1") {
        DataObj["imageurl1"] = "";
        DataObj["imageurl1"] = data.image_url;
        DataObj["image1Value"] = "";
        DataObj["image1Value"] = data.image_desc;
        DataObj["image1Caption"] = "";
        DataObj["image1Caption"] = data.image_Caption;
      } else if (data.type === "image2") {
        DataObj["imageurl2"] = "";
        DataObj["imageurl2"] = data.image_url;
        DataObj["image2Value"] = "";
        DataObj["image2Value"] = data.image_desc;
        DataObj["image2Caption"] = "";
        DataObj["image2Caption"] = data.image_Caption;
      } else if (data.type === "image3") {
        DataObj["imageurl3"] = "";
        DataObj["imageurl3"] = data.image_url;
        DataObj["image3Value"] = "";
        DataObj["image3Value"] = data.image_desc;
        DataObj["image3Caption"] = "";
        DataObj["image3Caption"] = data.image_Caption;
      } else if (data.type === "authorImg") {
        setAuthorImg(data.image_url);
      }
      setImageuploaddata(DataObj);
    } catch (err) {
      console.log(err);
    }
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    const tagsfilteredArray = values?.tags?.join("|");
    const catagory = values?.category?.join("|");

    let requestObj = {
      data: {
        title: values?.title,
        Author: values?.author,
        Body: content,
        image1:
          props?.item?.id && props?.item?.attributes?.image1
            ? props?.item?.attributes?.image1
            : pic1,

        image2:
          props?.item?.id && props?.item?.attributes?.image2
            ? props?.item?.attributes?.image2
            : pic2,
        publishedAt: props?.item?.attributes?.publishedAt
          ? props?.item?.attributes?.publishedAt
          : null,
        profile:
          props?.item?.id && props?.item?.attributes?.profile
            ? props?.item?.attributes?.profile
            : authorImg,
        masterid: id,
        image1_description: imageuploaddata.image1Value,
        image2_description: imageuploaddata.image2Value,
        permalink: values?.permalink ? values?.permalink : "",
        NjayarTags: tagsfilteredArray,
        isHighPriority:
          values?.isHighPriority === 1 || isChecked === 1 ? true : false,
        isSecondPriority:
          values?.isHighPriority === 2 || isChecked === 2 ? true : false,
        isThirdPriority:
          values?.isHighPriority === 3 || isChecked === 3 ? true : false,
        category: catagory,
      },
    };
    let url =
      props?.item && props?.item?.id
        ? API.BASE_URL + API.NJAYAR_PRABHAADHAM + `/${props?.item?.id}`
        : API.BASE_URL + API.NJAYAR_PRABHAADHAM;
    let method = props?.item && props?.item.id ? "PUT" : "POST";
    axios({
      method: method,
      url: url,
      data: requestObj,
    })
      .then((response) => {
        setReturnedNewsId(response?.data?.data?.id);
        addTags(values?.tags);
        setLoading(false);
        if (props?.item?.attributes?.publishedAt) {
          navigate(`/Auth/NjayarScreen/njayarArticles/${id}`);
        }
        message.success(
          <>
            Success : <b>Saved</b>
          </>
        );
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  const onPublish = (newsid: any) => {
    setLoading3(true);
    let url = API.BASE_URL + API.NJAYAR_PRABHAADHAM + `/${newsid}`;
    let requestObj = {
      data: {
        publishedAt: new Date(),
      },
    };
    axios({
      method: "PUT",
      url: url,
      data: requestObj,
    })
      .then((response) => {
        message.success("Published");
        setLoading3(false);
        navigate(`/Auth/NjayarScreen/njayarArticles/${id}`);
        props.refresh();
      })
      .catch((error) => {
        setLoading3(false);
        console.log(error);
      });
  };

  const addImage = (image: any, caption: any) => {
    if (window.tinymce && window.tinymce.activeEditor) {
      const editor = window.tinymce.activeEditor;
      let Moment = `${moment().format(
        "YYYY-MM-DDHH:MM:SS"
      )}.suprabhaatham-news.png`;
      let alt = caption ? caption : Moment;
      editor.insertContent(`<img src="${image}" alt="${alt}" />`);
    }
  };

  const checkPermaLink = (e: any) => {
    const regex = /^[a-zA-Z0-9- ]*$/;
    const inputValue = e.target.value;
    const filteredValue = inputValue
      .split("")
      .filter((char: any) => regex.test(char))
      .join("");
    form.setFieldsValue({ permalink: filteredValue });
  };

  return (
    <div style={{ maxHeight: "90vh", overflow: "scroll" }}>
      <PageHeader title={"Njayar Pra. Articles Form"} />
      <Form
        onFinish={onFinish}
        form={form}
        name="add-editorials"
        initialValues={{
          title:
            props?.item &&
            props?.item?.id &&
            props?.item?.attributes &&
            props?.item?.attributes?.title,
          author:
            props?.item &&
            props?.item?.id &&
            props?.item?.attributes &&
            props?.item?.attributes?.Author,
          tags:
            restTags &&
            restTags.length &&
            restTags.map((item: any) => {
              return item;
            }),
          category:
            restCat &&
            restCat.length &&
            restCat.map((item: any) => {
              return item;
            }),
        }}
      >
        <Container>
          <Row>
            <Col sm={9} xs={12}>
              <Row>
                <Col md={6}>
                  <label className="newsFormLabels">Title</label>
                  <Form.Item name="title">
                    <Input.TextArea />
                  </Form.Item>
                  <label className="newsFormLabels">Tags</label>
                  <Form.Item name="tags">
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      filterOption={(input: any, option: any) =>
                        option?.children
                          ?.toLowerCase()
                          ?.indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {tags.length > 0 &&
                        tags.map((item: any, index: any) => (
                          <Select.Option key={index} value={item.tag}>
                            {item.tag}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col md={6}>
                  <label className="newsFormLabels">Byline</label>
                  <Form.Item name="author">
                    <Input.TextArea />
                  </Form.Item>
                  <label className="newsFormLabels">Category</label>
                  <Form.Item name="category">
                    <Select
                      mode="tags"
                      style={{ width: "100%" }}
                      filterOption={(input: any, option: any) =>
                        option?.children
                          ?.toLowerCase()
                          ?.indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {njayarCAt?.length > 0 &&
                        njayarCAt?.map((item: any, index: any) => (
                          <Select.Option
                            key={index}
                            value={item?.replace("|", "")}
                          >
                            {item?.replace("|", "")}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {loading2 && <Loadingbox />}
              <input
                id="my-file"
                type="file"
                name="my-file"
                style={{ display: "none" }}
                onChange={() => {}}
              />
              <Editor
                // apiKey={API.EDITOR_API_KEY}
                value={content}
                init={{
                  height: 500,
                  menubar: true,
                  directionality: "ltr",
                  plugins:
                    "advlist autolink lists link image pageembed code preview charmap print preview anchor help searchreplace visualblocks code insertdatetime media table paste wordcount pageembed code preview image code",
                  toolbar:
                    "  | bold italic | \
                      alignleft aligncenter alignright | \
                      bullist numlist outdent indent |subscript superscript |link unlink| help | forecolor backcolor | \
                      \
                      \
                           \
                           | image | myCustomButton | joinButton | TwitterEmbed",
                  setup: (editor) => {
                    editor.ui.registry.addButton("myCustomButton", {
                      text: "Media Library",
                      icon: "gallery",
                      onAction: () => {
                        setImageType("Media");
                        setUploadImg(true);
                      },
                    });
                    editor.ui.registry.addButton("joinButton", {
                      text: "Custom Button",
                      onAction: () => {},
                    });
                  },
                  file_browser_callback_types: "image",
                  file_picker_callback: function (callback, value, meta) {
                    if (meta.filetype == "image") {
                      var input = document.getElementById("my-file") as any;
                      input.click();
                      input.onchange = function () {
                        var file = input.files[0];
                        var reader = new FileReader();
                        reader.onload = function (e: any) {
                          console.log("name", e.target.result);
                          callback(e.target.result, {
                            alt: file.name,
                          });
                        };
                        reader.readAsDataURL(file);
                      };
                    }
                  },
                  paste_data_images: true,
                }}
                onEditorChange={handleEditorChange}
                onInit={() => setLoading2(false)}
              />
              <br />

              <Row>
                <Col sm={4}>
                  <div className="NewsImagePickerItem">
                    <div className="newsFormLabels">News Image 1</div>
                    <div className="NewsImageBox">
                      {imageuploaddata?.imageurl1 === "" ? (
                        <>
                          <BsFillPlusCircleFill
                            color="#c2c2c2"
                            size={40}
                            onClick={() => {
                              if (imageuploaddata?.imageurl1 === "") {
                                setImageType("image1");
                                setImageName("Image 1");
                                setUploadImg(true);
                              }
                            }}
                          />
                          <br />
                          <div className="imageUplaod-Txt">Click to Upload</div>
                        </>
                      ) : (
                        <>
                          <LuMinus
                            onClick={() => deleteImagesFromList("imageurl1")}
                            className="image-delete-btn"
                            size={25}
                          />
                          <img
                            alt="img"
                            className="NewsImageBox-img"
                            src={imageuploaddata?.imageurl1}
                            onClick={() => {
                              setImageType("image1");
                              setImageName("Image 1");
                            }}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="NewsImagePickerItem">
                    <div className="newsFormLabels">News Image 2</div>
                    <div className="NewsImageBox">
                      {imageuploaddata?.imageurl2 === "" ? (
                        <>
                          <BsFillPlusCircleFill
                            color="#c2c2c2"
                            size={40}
                            onClick={() => {
                              if (imageuploaddata?.imageurl2 === "") {
                                setImageType("image2");
                                setImageName("Image 2");
                                setUploadImg(true);
                              }
                            }}
                          />
                          <br />
                          <div className="imageUplaod-Txt">Click to Upload</div>
                        </>
                      ) : (
                        <>
                          <LuMinus
                            onClick={() => {
                              imageuploaddata["imageurl2"] = "";
                              deleteImagesFromList("imageurl2");
                            }}
                            className="image-delete-btn"
                            size={25}
                          />
                          <img
                            alt="img"
                            className="NewsImageBox-img"
                            src={imageuploaddata?.imageurl2}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </Col>
                <Col sm={4}>
                  <div className="NewsImagePickerItem">
                    <div className="newsFormLabels">Author Image</div>
                    <div className="NewsImageBox">
                      {imageuploaddata?.imageurl3 === "" ? (
                        <>
                          <BsFillPlusCircleFill
                            color="#c2c2c2"
                            size={40}
                            onClick={() => {
                              if (imageuploaddata?.imageurl3 === "") {
                                setImageType("authorImg");
                                setImageName("authorImg");
                                setUploadImg(true);
                              }
                            }}
                          />
                          <br />
                          <div className="imageUplaod-Txt">Click to Upload</div>
                        </>
                      ) : (
                        <>
                          <LuMinus
                            onClick={() => deleteImagesFromList("imageurl3")}
                            className="image-delete-btn"
                            size={25}
                          />
                          <img
                            alt="img"
                            className="NewsImageBox-img"
                            src={imageuploaddata?.imageurl3}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col
              sm={3}
              xs={12}
              style={{ borderLeft: "1px solid rgb(222, 222, 222)" }}
            >
              <label className="newsFormLabels">Perma-Link</label>
              <Form.Item
                name="permalink"
                rules={[
                  {
                    validator: (_, value) => {
                      const regex = /^[a-zA-Z0-9- ]*$/;
                      return regex.test(value)
                        ? Promise.resolve()
                        : Promise.reject(
                            "Only alphanumeric characters, spaces, and dashes are allowed."
                          );
                    },
                  },
                ]}
              >
                <Input.TextArea
                  size="large"
                  lang="en"
                  onChange={checkPermaLink}
                />
              </Form.Item>
              <div className="newsFormLabels">Priority</div>
              <Form.Item name="isHighPriority">
                <Radio.Group
                  className="square-radio-button"
                  defaultValue={isChecked}
                  size="large"
                >
                  <Radio value={1}>First leads</Radio>
                  <Radio value={2}>Second leads</Radio>
                  <Radio value={3}>Third leads</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <br />
          <br />
          <hr />
          <Row>
            <Col sm={6} />
            <Col sm={3}>
              {returnedNewsId ? (
                <Button
                  block
                  style={{ height: 50 }}
                  type="primary"
                  onClick={() => onPublish(returnedNewsId)}
                  icon={<BsUpload />}
                  loading={loading3}
                >
                  Publish
                </Button>
              ) : null}
            </Col>
            <Col sm={3}>
              <Button
                block
                style={{ height: 50 }}
                htmlType="submit"
                loading={loading}
              >
                {props?.item?.id ? "Update" : " Save"}
              </Button>
            </Col>
          </Row>
        </Container>
      </Form>
      {uploadImg ? (
        <ImagePicker
          mode="create"
          imageType={imageType}
          imageName={imageName}
          onSelect={addImage}
          onSubmit={(val: any) => addImagesToList(val)}
          visible={uploadImg}
          close={() => setUploadImg(false)}
        />
      ) : null}
    </div>
  );
}

export default NewFormNjayarArticles;
