import { useState } from "react";
import {
  Space,
  Table,
  Tag,
  Popconfirm,
  Switch,
  Pagination,
  Button,
} from "antd";
import type { ColumnsType } from "antd/es/table";
import { IoTrashOutline } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { BiEdit } from "react-icons/bi";
import { SiMicrosoftexcel } from "react-icons/si";

function Datatable(props: any) {
  const [sortedInfo, setSortedInfo] = useState<any>({});
  const navigate = useNavigate();

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    setSortedInfo(sorter);
  };

  const handleSwitchChange = (checked: any, id: any) => {
    props.onUpdate(id, checked === false ? true : false);
  };

  interface DataType {
    key: string;
    id: number;
    news: string;
    newshead: string;
    address: string;
    imageurl: string[];
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      width: 50,
      render: (text, record, index) => {
        return <div className="">{index + 1}</div>;
      },
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
      width: 500,
      sorter: (a: any, b: any) => a.username.localeCompare(b.username),
      sortOrder: sortedInfo.columnKey === "username" && sortedInfo.order,
      render: (text, record: any) => {
        return (
          <div onClick={() => navigate(`/Auth/users/form/${record.id}`)}>
            {text}
          </div>
        );
      },
    },
    {
      title: "Role",
      dataIndex: "Role",
      key: "role",
      width: 500,
      render: (text, record: any) => {
        return (
          <Tag onClick={() => navigate(`/Auth/users/form/${record.id}`)}>
            {record?.role?.name}
          </Tag>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: 500,
      render: (text, record: any) => {
        return (
          <div onClick={() => navigate(`/Auth/users/form/${record.id}`)}>
            {text}
          </div>
        );
      },
    },
    {
      title: "Posts",
      dataIndex: "all_news",
      key: "all_news",
      width: 500,
      render: (text, record: any) => {
        return (
          <div style={{ fontSize: "10px" }}>{record?.all_news?.count}</div>
        );
      },
    },
    {
      title: "Export Log",
      key: "Export",
      width: 70,
      render: (text, record: any) => {
        return (
          <>
            <Button
              onClick={() => {
                props?.DoExport();
                props?.SelectedLog(record);
              }}
              loading={props?.loading3[record.id]}
              icon={<SiMicrosoftexcel color="green" />}
            >
              Export
            </Button>
          </>
        );
      },
    },
    {
      title: "Status",
      key: "publishedAt",
      width: 200,
      render: (text, record: any) => {
        return (
          <Space size="middle">
            <Tag
              color={record?.blocked ? "red" : "green"}
              style={{ fontSize: "10px" }}
            >
              {record?.blocked ? "Inactive" : "Active"}
            </Tag>
          </Space>
        );
      },
    },
    {
      title: "blocked",
      key: "blocked",
      width: 70,
      render: (text, record: any) => {
        return (
          <Switch
            checked={text?.blocked === false}
            onChange={(val) => handleSwitchChange(val, record?.id)}
          />
        );
      },
    },

    {
      title: "Task",
      key: "action",
      render: (_, record) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              alignContent: "center",
            }}
          >
            <Space size="middle" style={{ marginRight: 10 }}>
              <BiEdit
                size={20}
                color="#f5a442"
                cursor={"pointer"}
                onClick={() => navigate(`/Auth/users/form/${record.id}`)}
              />
            </Space>
            <Popconfirm
              title="Delete the task"
              description="Are you sure to delete this task?"
              okText="Yes"
              cancelText="No"
              onConfirm={() => props.onDelete(record.id)}
            >
              <Space size="middle">
                <IoTrashOutline size={20} color="red" cursor={"pointer"} />
              </Space>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ marginTop: 10 }}>
      <Table
        columns={columns}
        dataSource={props.data}
        pagination={false}
        onChange={handleChange}
        size="small"
      />
      <br />
      <div style={{ float: "right" }}>
        <Pagination
          responsive
          defaultCurrent={props?.page}
          total={props.meta && props.meta}
          pageSize={props?.pagesize}
          onChange={(p, t) => {
            props.onPageChange(p, t);
          }}
        />
      </div>
    </div>
  );
}

export default Datatable;
