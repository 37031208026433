import { Modal, DatePicker, Space, Button, Row, Col } from "antd";
import type { Dayjs } from "dayjs";
import { useState } from "react";
import { BsCalendar2Date } from "react-icons/bs";
import { IoFileTrayFullOutline } from "react-icons/io5";
import { MdDownload } from "react-icons/md";

const { RangePicker } = DatePicker;
function ExportExcelMOdal(props: any) {
  const [showDowloade, setShowDowloade] = useState(false);
  const [showRange, setShowRange] = useState(false);

  const [startDate, setstartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const onRangeChange = (
    dates: null | (Dayjs | null)[],
    dateStrings: string[]
  ) => {
    if (dates) {
      setShowDowloade(true);
      console.log("From: ", dates[0], ", to: ", dates[1]);
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setstartDate(dateStrings[0]);
      setEndDate(dateStrings[1]);
    } else {
      console.log("Clear");
      setShowDowloade(false);
    }
  };
  return (
    <Modal
      open={props.visible}
      onCancel={() => props.close()}
      centered
      width={500}
      footer={false}
    >
      <div style={{ fontFamily: "Noto_Sans_SemiBold_600", fontSize: "16px" }}>
        Export Log
      </div>
      <br />
      <Row gutter={[16, 16]}>
        {!showRange ? (
          <>
            <Col sm={12}>
              <Button
                style={{
                  width: "100%",
                  height: "100px",
                }}
                onClick={() => {
                  props?.generateExcel(props?.data, null, null);
                  props?.close();
                }}
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <IoFileTrayFullOutline size={30} />
                  Get all
                </div>
              </Button>
            </Col>
            <Col sm={12}>
              <Button
                style={{
                  width: "100%",
                  height: "100px",
                }}
                onClick={() => setShowRange(true)}
              >
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-around",
                  }}
                >
                  <BsCalendar2Date size={30} />
                  By date
                </div>
              </Button>
            </Col>
          </>
        ) : (
          <RangePicker style={{ width: "100%" }} onChange={onRangeChange} />
        )}
        {showDowloade ? (
          <Button
            type="primary"
            onClick={() => {
              props?.generateExcel(props?.data, startDate, endDate);
              props?.close();
              setShowRange(false);
            }}
            loading={props?.loading3}
          >
            Download &nbsp; <MdDownload />
          </Button>
        ) : null}
      </Row>
    </Modal>
  );
}

export default ExportExcelMOdal;
